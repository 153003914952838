import React from "react";
import SignIn from "../components/accounts/SignIn";

class SignInPage extends React.Component {
  render() {
    console.log("Sign in page");
    return <SignIn />;
  }
}

export default SignInPage;
