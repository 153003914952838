interface ValidationErrorDetails {
  attribute: string;
  message: string;
  required: string;
}
interface ValidationError {
  message?: string;
  validation?: ValidationErrorDetails[];
}

export class SubmissionError extends Error {
  readonly details: Record<string, string>;

  constructor(message: string, details: Record<string, string> = {}) {
    super(message);
    this.details = details;
  }
}

export const throwSubmissionError = (
  error: ValidationError
): Record<string, string> => {
  const errorDetails = (error.validation || []).reduce<Record<string, string>>(
    (prev, next) => {
      prev[next.attribute] = next.message;
      return prev;
    },
    {}
  );

  throw new SubmissionError(error.message || "Unexpected error", errorDetails);
};
