import {
  createStyles,
  TextField,
  Theme,
  WithStyles,
  withStyles
} from "@material-ui/core";
import { TextFieldProps } from "@material-ui/core/TextField";
import * as React from "react";
import { FieldRenderProps } from "react-final-form";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      border: `solid thin ${theme.palette.grey[400]}`,
      borderRadius: theme.shape.borderRadius,
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      transition: "background 0.2s",

      "&$focused": {
        borderColor: theme.palette.primary.dark,
        background: "rgba(255, 255, 255, 0.8)"
      },
      "&$error": {
        borderColor: theme.palette.error.main
      },
      "&$disabled": {
        borderColor: theme.palette.grey[300]
      }
    },
    dense: {
      paddingTop: 6
    },
    label: {
      color: theme.palette.grey[800]
    },
    labelShrink: {
      color: theme.palette.grey[400],
      "&$labelDense": {
        transform: `translate(0, 1.75px) scale(0.75);`
      }
    },
    labelDense: {
      transform: `translate(${theme.spacing(2)}px, ${theme.spacing(1) *
        4}px) scale(1);`
    },
    error: {},
    focused: {},
    disabled: {}
  });

const Input: React.SFC<
  FieldRenderProps & TextFieldProps & WithStyles<typeof styles>
> = ({
  input: { name, onChange, value, ...restInput },
  meta,
  placeholder,
  classes,
  helperText,
  ...rest
}) => {
  const errorState =
    (meta.touched && meta.error) ||
    (meta.submitFailed && !meta.dirtySinceLastSubmit && meta.submitError);

  let errorMessage;
  if (errorState) {
    errorMessage = meta.submitError || meta.error;
  }

  return (
    <TextField
      {...rest}
      name={name}
      helperText={errorMessage || helperText}
      error={Boolean(errorState)}
      placeholder={placeholder}
      InputProps={{
        ...(restInput as any),
        ...rest.InputProps,
        disableUnderline: true,
        classes: {
          root: classes.root,
          focused: classes.focused,
          error: classes.error,
          disabled: classes.disabled,
          inputMarginDense: classes.dense
        }
      }}
      InputLabelProps={{
        classes: {
          root: classes.label,
          shrink: classes.labelShrink,
          marginDense: classes.labelDense
        }
      }}
      onChange={onChange}
      value={value}
    />
  );
};

export default withStyles(styles)(Input);
