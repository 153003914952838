import { Theme, MuiThemeProvider } from "@material-ui/core";
import * as React from "react";

const warningTheme = (theme: Theme) => ({
  ...theme,
  palette: {
    ...theme.palette,
    primary: {
      main: theme.palette.error.main,
      light: theme.palette.error.light,
      dark: theme.palette.error.dark,
      contrastText: "#ffffff"
    }
  }
});

const Warning: React.SFC<{}> = ({ children }) => (
  <MuiThemeProvider theme={warningTheme}>{children}</MuiThemeProvider>
);

export default Warning;
