import * as React from "react";
import { Auth } from "aws-amplify";
import Form from "../common/Form";
import Warning from "../common/Warning";
import {
  Button,
  createStyles,
  IconButton,
  InputAdornment,
  Theme,
  Typography,
  WithStyles,
  withStyles
} from "@material-ui/core";
import { Field } from "react-final-form";
import { required } from "../../utils/validators";
import Input from "../common/BootstrapTextField";
import { SubmissionError } from "../../utils/errors";
import { withAuth, WithAuth } from "../auth/withAuth";
import { Location, Redirect, WindowLocation } from "@reach/router";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { showMessage } from "../common/Toast";
import { finishLoading, startLoading } from "../common/PageProgressBar";
import { navigate } from "gatsby-link";

const styles = (theme: Theme) =>
  createStyles({
    submit: {
      width: "100%",
      marginTop: theme.spacing(4),
      textTransform: "capitalize",
      height: 50,
      fontSize: 18
    },
    tabs: {
      width: 300,
      marginLeft: "auto",
      marginRight: "auto"
    },
    tab: {
      width: 150,
      fontSize: 14,
      textTransform: "capitalize"
    },
    showPassword: {
      color: theme.palette.grey[500],
      marginRight: -theme.spacing(2)
    }
  });

export interface SignInData {
  login: string;
  password: string;
}

interface Props extends WithAuth, WithStyles<typeof styles> {
  location: WindowLocation;
}

interface State {
  signingIn?: boolean;
  authenticated?: boolean;
  showPassword: boolean;
}

export class SignIn extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.checkAuthentication();

    this.state = {
      showPassword: false
    };
  }

  componentDidMount() {
    if (this.props.location.state) {
      const { message = undefined, error = false } = this.props.location.state;
      if (message) {
        showMessage(message, error);
      }
    }
  }

  componentDidUpdate() {
    this.checkAuthentication();
  }

  render() {
    const { classes } = this.props;
    const { signingIn } = this.state;

    if (!this.state.authenticated) {
      return (
        <React.Fragment>
          <Form<SignInData> onSubmit={this.signIn} showErrorToasts={false}>
            {({ handleSubmit, submitting, submitError }) => (
              <form onSubmit={handleSubmit}>
                {submitError && (
                  <Warning>
                    <Typography color="primary" paragraph>
                      {submitError}
                    </Typography>
                  </Warning>
                )}

                <Field
                  name="login"
                  component={Input}
                  label="Email"
                  fullWidth
                  margin="dense"
                  autoComplete="username"
                  validate={required("Email is required")}
                />
                <Field
                  name="password"
                  component={Input}
                  label="Password"
                  autoComplete="current-password"
                  type={this.state.showPassword ? "text" : "password"}
                  fullWidth
                  margin="dense"
                  validate={required("Password is required")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Toggle password visibility"
                          onClick={this.togglePassword}
                          className={classes.showPassword}
                        >
                          {this.state.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={submitting || signingIn}
                >
                  Sign In
                </Button>
              </form>
            )}
          </Form>
        </React.Fragment>
      );
    }

    return <Redirect noThrow to="/" />;
  }

  private signIn = async (data: SignInData) => {
    try {
      startLoading();

      await Auth.signIn(data.login, data.password);

      this.setState({
        signingIn: true
      });

      navigate("/implicit/callback");
    } catch (err) {
      console.log(err);

      if (err.code === "UserNotConfirmedException") {
        // The error happens if the user didn't finish the confirmation step when signing up
        // In this case you need to resend the code and confirm the user
        // About how to resend the code and confirm the user, please check the signUp part
        throw new SubmissionError(err.message);
      } else if (err.code === "PasswordResetRequiredException") {
        // The error happens when the password is reset in the Cognito console
        // In this case you need to call forgotPassword to reset the password
        // Please check the Forgot Password part.
        throw new SubmissionError(err.message);
      }

      throw new SubmissionError("Unexpected error logging in");
    } finally {
      finishLoading();
    }
  };

  private checkAuthentication = () => {
    const check = async () => {
      const authenticated = await this.props.auth.isAuthenticated();
      if (authenticated !== this.state.authenticated) {
        this.setState({ authenticated });
      }
    };

    // Dismiss login screen if already authenticated
    check().catch(ex => console.error(ex));
  };

  private togglePassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };
}

const SignInPage = withAuth(withStyles(styles)(SignIn));

export default (props: any) => (
  <Location>
    {({ location }) => <SignInPage {...props} location={location} />}
  </Location>
);
