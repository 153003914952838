import { FormApi, FORM_ERROR } from "final-form";
import * as React from "react";
import { Form as FinalForm, FormProps } from "react-final-form";
import { Omit } from "../../utils/typesafe";
import { showMessage } from "./Toast";
import _ from "lodash";

interface Props<T, U = {}> extends Omit<FormProps, "onSubmit"> {
  onSubmit: (data: T, formApi: FormApi) => Promise<U | void>;
  onSuccess?: (result: U, formApi: FormApi) => void;
  successMessage?: string;
  showErrorToasts?: boolean;
}

class Form<T, U = {}> extends React.Component<Props<T, U>> {
  public render() {
    const {
      onSubmit,
      successMessage,
      showErrorToasts,
      onSuccess,
      ...rest
    } = this.props;

    return (
      <FinalForm
        {...rest}
        onSubmit={safeSubmit(onSubmit, {
          successMessage,
          onSuccess,
          showErrorToasts
        })}
      />
    );
  }
}

interface SafeSubmitOptions<R> {
  successMessage: string;
  showErrorToasts?: boolean;
  onSuccess: (result: R, formApi: FormApi) => void;
}

type FormHandler<T> = (data: Partial<T>, formApi: FormApi) => Promise<any>;

function safeSubmit<T, R = {}>(
  handler: FormHandler<T>,
  config: Partial<SafeSubmitOptions<R>> = {}
): FormHandler<T> {
  return async (data, api) => {
    try {
      const result = await handler(data, api);

      if (config.successMessage) {
        showMessage(config.successMessage, false);
      }

      if (config.onSuccess) {
        console.log(api);
        config.onSuccess(result, api);
      }

      return {};
    } catch (ex) {
      let message: string;

      if (ex.graphQLErrors) {
        console.log(ex.graphQLErrors);
        message = _.get(
          ex.graphQLErrors,
          [0, "message"],
          "Unexpected error submitting form"
        );

        // handle UserInputError
        const errorType = _.get(
          ex.graphQLErrors,
          [0, "extensions", "code"],
          null
        );

        if (errorType === "BAD_USER_INPUT") {
          ex.details = _.get(ex.graphQLErrors, [0, "extensions", "exception"]);
        }
      } else {
        message = ex.message || "Unexpected error submitting form";
      }

      if (config.showErrorToasts) {
        showMessage(message, true);
      }

      return {
        [FORM_ERROR]: message,
        ...ex.details
      };
    }
  };
}

export default Form;
